/* -----------------------------------------------------------------------------

# blog Styles

----------------------------------------------------------------------------- */
.latest-blog-bg {
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-color: #ddd;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.single-latest-blog h3 {
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 600;

  a {
    color: $body-color;
  }
}

p {
  &.blog-meta span {
    margin-right: 15px;
    opacity: 0.6;
    color: $body-color;
    font-size: 0.85em;

    &:last-child {
      margin-right: 0;
    }

    i {
      margin-right: 5px;
    }
  }

  &.excerpt {
    line-height: 1.8;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.latest-blog a.boxed-btn {
  margin-top: 80px;
}

.blog-text-box {
  padding: 25px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.single-latest-blog {
  margin-bottom: 30px;
}

.single-article-bg {
  height: 450px;
}

.pagination-wrap {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        color: #6f6f6f;
        font-size: 15px;
        background-color: #f3f3f3;
        display: inline-block;
        padding: 8px 14px;
        border-radius: 5px;
        margin: 3px;
        font-weight: 600;
        border-radius: 50px;

        &.active {
          background-color: $primary;
        }
      }
    }
  }

  margin-top: 40px;
}

//Single blog
.single-article-bg {
  background-size: cover;
  background-position: center;
  background-color: #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.single-article-text {
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    line-height: 1.6;
    color: $body-color;
  }
}

.comments-list-wrap {
  margin: 100px 0;

  h3 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
  }
}

.comment-template h4 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
}

.single-comment-body {
  position: relative;
}

.comment-user-avater {
  position: absolute;
  left: 0;
  top: 0;

  img {
    width: 60px;
    max-width: 60px;
    border-radius: 50%;
  }
}

.comment-text-body {
  padding-left: 80px;
  margin-bottom: 40px;

  h4 {
    font-size: 18px;
    font-weight: 600;
  }
}

span.comment-date {
  opacity: 0.5;
  font-size: 80%;
  font-weight: 700;
  margin-left: 5px;
}

.comment-text-body h4 a {
  color: $body-color;
  font-size: 80%;
  margin-left: 10px;
  border-bottom: 1px solid #aaa;
}

.single-comment-body.child {
  margin-left: 75px;
}

.comment-text-body p {
  color: #888;
  line-height: 2;
  margin: 0;
}

.comment-template {
  h4 {
    margin-bottom: 10px;
  }

  > p {
    opacity: 0.7;
    margin-bottom: 30px;
  }

  form p {
    input {
      &[type=text] {
        border: 1px solid #ddd;
        width: 49%;
        padding: 15px;
        border-radius: 5px;
        font-size: 15px;
        color: $body-color;
      }

      &[type=email] {
        border: 1px solid #ddd;
        width: 49%;
        padding: 15px;
        border-radius: 5px;
        font-size: 15px;
        color: $body-color;
        margin-left: 10px;
      }
    }

    textarea {
      border: 1px solid #ddd;
      padding: 15px;
      font-size: 15px;
      color: $body-color;
      border-radius: 5px;
      height: 250px;
      resize: none;
      width: 100%;
    }
  }
}

.sidebar-section {
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      a {
        color: #555;
        font-size: 15px;
      }

      line-height: 1.5;
    }
  }

  > div {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  margin-left: 30px;
}

.recent-posts ul li, .archive-posts ul li {
  position: relative;
  padding-left: 17px;
  margin-bottom: 10px;
}

.recent-posts ul li:before, .archive-posts ul li:before {
  position: absolute;
  left: 0;
  top: 2px;
  content: "\003E";
  font-weight: 900;
}

.tag-section ul li {
  display: inline-block;

  a {
    background-color: #ddd;
    padding: 3px 10px;
    display: block;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
  }
}