
@font-face {
  font-family: 'Poppins-Black';
  src: url('assets/fonts/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url('assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url('assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url('assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url('assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Italic';
  src: url('assets/fonts/Poppins-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Light';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-LightItalic';
  src: url('assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('assets/fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url('assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url('assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Thin';
  src: url('assets/fonts/Poppins-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url('assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

/********** Template CSS **********/
:root {
  --primary: #00A398;
  --secondary: #F78F00;
  --light: #E8F5E9;
  --dark: #1C5858;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

h1,
.h1,
h2,
.h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
.h3,
h4,
.h4,
.fw-medium {
  font-weight: 600 !important;
}

h5,
.h5,
h6,
.h6,
.fw-semi-bold {
  font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: var(--light);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand,
.navbar a.btn {
  height: 80px
}

.navbar .navbar-nav .nav-link {
  margin-right: 35px;
  padding: 25px 0;
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  display: none;
  /* border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px; */
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(15, 66, 41, .6);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: var(--primary);
  border: 10px solid var(--primary);
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
      position: relative;
      min-height: 450px;
  }
  
  #header-carousel .carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

.page-header {
  background: linear-gradient(rgba(15, 66, 41, .6), rgba(15, 66, 41, .6)), url(./assets/images/slider3.jpg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
  color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-size: 18px;
  color: var(--light);
}


/*** Top Feature ***/
@media (min-width: 991.98px) {
  .top-feature {
      position: relative;
      margin-top: -80px;
      z-index: 1;
  }
}


/*** Facts & Quote ***/
.facts,
.quote {
  background: rgba(15, 66, 41, .6);
}


/*** Service ***/
.service-item {
  position: relative;
  text-align: center;
}

.service-item .service-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.service-item .service-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  object-fit: cover;
  transform: scale(1.2);
  transition: .3s;
  z-index: -1;
}

.service-item:hover .service-img img {
  transform: scale(1);
}

.service-item .service-text {
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .3s;
}

.service-item:hover .service-text {
  background: rgba(15, 66, 41, .6);
}

.service-item .service-text h4,
.service-item .service-text p {
  transition: .3;
}

.service-item:hover .service-text h4 {
  color: #FFFFFF;
}

.service-item:hover .service-text p {
  color: var(--light);
}

.service-item .service-text .btn-square {
  width: 100px;
  height: 100px;
  background: transparent;
  transition: .5s;
}

.service-item:hover .service-text .btn-square {
  background: var(--light);
}

.service-item .service-text .btn, .single-latest-blog .btn {
  width: 112px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  color: var(--light);
  background: var(--secondary);
  white-space: nowrap;
  overflow: hidden;
  transition: .3s;
}

/* .service-item:hover .service-text .btn {
  width: 112px;
} */


/*** Project Portfolio ***/
#portfolio-flters {
  display: inline-block;
  background: var(--light);
  padding: 10px 15px;
}

#portfolio-flters li {
  display: inline-block;
  font-weight: 500;
  color: var(--primary);
  cursor: pointer;
  transition: .5s;
  border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
  color: var(--dark);
  border-color: var(--dark);
}

.portfolio-inner {
  position: relative;
  overflow: hidden;
}

.portfolio-inner::before,
.portfolio-inner::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(15, 66, 41, .6);
  transition: .5s;
}

.portfolio-inner::after {
  left: auto;
  right: 0;
}

.portfolio-inner:hover::before,
.portfolio-inner:hover::after {
  width: 50%;
}

.portfolio-inner .portfolio-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .5s;
  z-index: 3;
  opacity: 0;
}

.portfolio-inner:hover .portfolio-text {
  transition-delay: .3s;
  opacity: 1;
}

.portfolio-inner .portfolio-text .btn {
  background: var(--light);
  color: var(--primary);
}

.portfolio-inner .portfolio-text .btn:hover {
  background: var(--primary);
  color: var(--light);
}


/*** Team ***/
.team-item {
  position: relative;
  overflow: hidden;
}

.team-item .team-text {
  position: absolute;
  width: calc(100% - 45px);
  left: -100%;
  bottom: 45px;
  padding: 1.5rem;
  background: #FFFFFF;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-text {
  left: 0;
  opacity: 1;
}

.team-item .team-social .btn {
  background: var(--light);
  color: var(--primary);
}

.team-item .team-social .btn:hover {
  background: var(--primary);
  color: var(--light);
}

.team-item .team-img .team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  z-index: 3;
  opacity: 0;
}

.team-item:hover .team-img .team-social {
  transition-delay: .3s;
  opacity: 1;
}


/*** Testimonial ***/

.testimonial-carousel .owl-item img {
  width: 100px;
  height: 100px;
}

.testimonial-carousel .owl-nav {
  margin-top: 30px;
  display: flex;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin-right: 15px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: var(--light);
  border-radius: 4px;
  font-size: 22px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: var(--light);
}


/*** Footer ***/
.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\003E";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}

.copyright {
  color: var(--light);
  background: #072A19;
}

.copyright a {
  color: #FFFFFF;
}

.copyright a:hover {
  color: var(--primary);
}


/*Logo*/
.logo {
  height: 50px;
}

/* Home Slider */
.slick-slider {
  height: 100vh;
  overflow: hidden;
}
.slick-list {
  padding: 0 !important;
}
.slick-prev {
	left: 4%;
  z-index: 1;
}
.slick-next {
	right: 7%;
  z-index: 1;
}
.slick-prev:before, .slick-next:before {
	color: #000;
	content: none;
}

@media (max-width: 1024px) {
  .slick-slider {
    height: 300px;
  }
  .slick-next {
    right: 11%;
    z-index: 1;
  }
}

/* Testimonial Slider */
.testimonial-slider .slick-slider {
  height: auto;
}

.testimonial-slider {
  .slick-prev {
    left: 80%;
    z-index: 1;
    top: 80%;
  }
  .slick-next {
    right: 7%;
    z-index: 1;
    top: 80%;
  }
  .slick-prev:before, .slick-next:before {
    color: #000;
    content: none;
  }
}


/* Top Feature */
.top-feature-container {
  min-height: 160px;
}

/* Facts section */
.parallax-content {
  background: rgba(15, 66, 41, .6);
}
.facts-parallax {
  aspect-ratio: 8/1;
}

/* Contact section */
.contact-parallax {
  aspect-ratio: 2.1 ;
}

.image-404 {
  height: 65%;
}

/* Blog */
.single-latest-blog:hover .latest-blog-bg {
  opacity: 0.8;
}
.single-latest-blog {
  -webkit-box-shadow: 0 0 20px #dddddd;
  box-shadow: 0 0 20px #dddddd;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* .single-latest-blog:hover {
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
} */

/* Products detail page */
.single-product-img {
  margin-bottom: 30px;
}
.single-product-img img {
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px #ddd;
  box-shadow: 0 0 20px #ddd;
}

.single-product-content {
  margin-left: 30px;
}

.single-product-content h3 {
  font-size: 22px;
  font-weight: 600;
}

.single-product-content p {
  font-size: 15px;
  color: #555;
  line-height: 1.8;
}
