/******* Customized Bootstrap ********/

$primary: #00A398;
$secondary: #F78F00;
$light: #E8F5E9;
$dark: #153f3f;


$font-family-base: 'Poppins-Regular';

$headings-font-family: 'Poppins-SemiBold';

$body-color: #525368;

$headings-color: $dark;

$headings-font-weight: 700;

$display-font-weight: 700;

$enable-responsive-font-sizes: true;

$border-radius: 4px;

$border-radius-sm: $border-radius;

$border-radius-lg: $border-radius;

$link-decoration: none;

$enable-negative-margins: true;

@import "bootstrap/scss/bootstrap";