.arrow {
	$size: 20px;
	$width: 2px;
	cursor: pointer;
	display: block;
	width: $size / 1.414;
	height: $size / 1.414 * 2;
	position: relative;
    width: 4rem;
    height: 4rem;
    background-color: var(--primary);
    opacity: 0.8;
    border-radius: 50px;
	
	& span,
	&:before,
	&:after {
      opacity: 0.8;
	  background: #fff;
	  content: '';
	  display: block;
	  width: $size;
	  height: $width;
	  position: absolute;
	  top: calc(50% - (#{$width} / 2));
	}
	  
	&:before {
	  transform: rotate(-45deg);
	}
	&:after {
	  transform: rotate(45deg);
	}
	
	& span {
	  width: 0;
	}
	&:hover {
        opacity: 1;
        & span,
        &:before,
        &:after {
            opacity: 1;
        }
        & span {
            width: $size * 1.414;
            height: $width * 1.414;
        }
    }
    
	
	@each $direction in 'left' 'right' {
	  &.arrow--#{$direction} {
		& span,
		&:before,
		&:after {
		  #{$direction}: 25px;
		  transform-origin: #{$direction} 50%;
		}
  
		&:before,
		&:after {
		  transition: #{$direction} 0.3s 0.05s;

		}
  
		& span {
		  transition: width 0.3s, #{$direction} 0.3s 0.05s;
		}
  
		&:hover {
		  & span,
		  &:before,
		  &:after {
			#{$direction}: $size / 1.414 * 1;
		  }
		}
	  }
	}
}

@media (max-width: 1024px) { 
	.arrow {
		$size: 15px;
		$width: 2px;
		width: $size / 0.414;
		height: $size / 0.414 * 2;
			width: 3rem;
			height: 3rem;

		&:hover {
				& span {
						width: $size * 0.414;
						height: $width * 0.414;
				}
			}
			
		
		@each $direction in 'left' 'right' {
			&.arrow--#{$direction} {
			& span,
			&:before,
			&:after {
				#{$direction}: 16px;
				transform-origin: #{$direction} 50%;
			}
		
			&:hover {
				& span,
				&:before,
				&:after {
				#{$direction}: $size / 0.414 * 1;
				}
			}
			}
		}
	}
}